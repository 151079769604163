import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"

import { useFetchCollection } from "../../hooks/useSWR"
import { NFT } from "../../lib/types"
import { Card } from "./Card"

export const Gallery = () => {
  const { data, loading } = useFetchCollection()

  if (!data && loading) return <Text>Loading...</Text>

  return (
    <Flex h="100vh" pl={6}>
      <VStack>
        <Box alignSelf="flex-start">
          <Heading size="4xl" color="white">
            CLOUD CITY
          </Heading>
          <Heading size="2xl" color="white" opacity="50%">
            Microverse
          </Heading>
        </Box>
        <Box alignSelf="flex-start">
          <BatchTitles />
        </Box>
        <Box alignSelf="flex-start" pt={4}>
          <SimpleGrid templateColumns="repeat(3, 1fr)" gap={6} alignSelf="flex-start" pb={6}>
            {data && data.map((n, i) => <Card nft={n as NFT} />)}
          </SimpleGrid>
        </Box>
      </VStack>
    </Flex>
  )
}

const BatchTitles = () => {
  return (
    <HStack>
      <Wrap mt={10} justifyContent="space-between">
        <WrapItem>
          <Button variant="outline" borderColor="none">
            master keys
          </Button>
        </WrapItem>
        <WrapItem>
          <Button variant="outline" borderColor="none" disabled>
            city blocks
          </Button>
        </WrapItem>
        <WrapItem>
          <Button variant="outline" borderColor="none" disabled>
            modules
          </Button>
        </WrapItem>
        <WrapItem>
          <Button variant="outline" borderColor="none" disabled>
            cloud keys
          </Button>
        </WrapItem>
      </Wrap>
    </HStack>
  )
}
