import "./theme/App.scss"

import { useDisclosure } from "@chakra-ui/react"
import React, { useState } from "react"
import { Routes, Route, useLocation, BrowserRouter } from "react-router-dom"

import { useWeb3Context } from "web3-react"

import Background from "./assets/background.jpg"
import { Backdrop } from "./components/Backdrop"
import { Header } from "./components/Header"
import { Auction, Gallery } from "./pages"
import ErrorBoundary from "./services/errors/ErrorBoundary"
import Web3Provider from "./services/web3/Web3Provider"
import { ThemeProvider } from "./theme"
import { MetaMaskProvider } from "./hooks/useMetaMask"

export const App = () => {
  return (
    <div
      className="App"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundSize: "cover",
        height: "calc(100% + 50px)",
      }}
    >
      <BrowserRouter>
        <ThemeProvider>
          <ErrorBoundary>
            <AppLayout />
          </ErrorBoundary>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  )
}

const AppLayout = () => {
  const canAccess = useAppGuard()
  const connectModal = useDisclosure()
  const enabled = useLocation().pathname.includes("token")

  return (
    <>
      <Web3Provider>
        <MetaMaskProvider>
          <Header />
          <Backdrop enabled={enabled}>
            <Routes>
              <Route path="/" element={<Gallery />} />
              <Route path="/token/:id" element={<Auction />} />
            </Routes>
          </Backdrop>
        </MetaMaskProvider>
      </Web3Provider>
    </>
  )
}

const useAppGuard = () => {
  const context = useWeb3Context()
  const [canAccess, setCanAccess] = useState(true)

  return canAccess
}
