import axios from "axios"
import useSWR from "swr"

import { AUCTION_URL, COLLECTION_URL } from "../constant"
import { transformOpenseaResponse } from "../lib"

export const useFetchCollection = () => {
  const fetcher = async (u) => {
    return await axios({
      method: "GET",
      url: u,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => res.data.assets.map((r) => transformOpenseaResponse(r)))
  }

  const { data, error, isValidating } = useSWR(COLLECTION_URL, fetcher, {
    dedupingInterval: 0,
  })

  return { data, error, loading: isValidating }
}

export const useFetchAuction = (id) => {
  const API = AUCTION_URL + id
  const fetcher = async (u) => {
    return await axios({
      method: "GET",
      url: u,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(async (res) => transformOpenseaResponse(res.data))
  }

  const { data, error, isValidating } = useSWR(API, fetcher, {
    dedupingInterval: 0,
  })

  return { data, error, loading: isValidating }
}
