import { Box, Flex, VisuallyHidden } from "@chakra-ui/react"
import { ConnectWalletButton } from "./ConnectWallet"

export const Header = () => {
  return (
    <Flex justifyContent="space-between" w="100%" mb={4}>
      <Box>
        <VisuallyHidden />
      </Box>
      <ConnectWalletButton />
    </Flex>
  )
}
