import { Box, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react"
import React, { Suspense } from "react"
import { useNavigate } from "react-router-dom"

import { etherToHumanReadable } from "../../lib"

export const Card = ({ nft }) => {
  const { name, imageOriginalUrl, tokenId, bids } = nft
  const navigate = useNavigate()
  const getLastBid = (bids) => bids.length && etherToHumanReadable(bids[0].bid)

  return (
    <Box
      maxW="lg"
      borderRadius="8px"
      overflow="hidden"
      background="rgba(255, 255, 255, 0.1)"
      p="16px"
      onClick={() => navigate("/token/" + tokenId)}
    >
      <VStack>
        <Heading alignSelf="flex-start" color="white">
          {name}
        </Heading>
        <Suspense fallback={<h1>Loading profile...</h1>}>
          <Box>
            <Image
              src={imageOriginalUrl}
              alt={name}
              objectFit="cover"
              minW="500px"
              h="500px"
              w="500px"
            />
          </Box>
        </Suspense>

        <HStack w="full" justifyContent="space-between">
          <Text fontSize="lg" color="gray.500">
            last bid
          </Text>
          <HStack>
            <Text fontSize="lg" color="white">
              {getLastBid(bids)}
            </Text>
            <Text fontSize="lg" color="gray.500">
              ETH
            </Text>
          </HStack>
        </HStack>
        <HStack w="full" justifyContent="space-between">
          <Text fontSize="lg" color="gray.500">
            available
          </Text>
          <HStack>
            <Text fontSize="lg" color="white">
              {tokenId}
            </Text>
            <Text fontSize="lg" color="gray.500">
              / 64
            </Text>
          </HStack>
        </HStack>
      </VStack>
    </Box>
  )
}
