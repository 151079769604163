import { Box, Button, FormControl, HStack, Input } from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"

export const PlaceBid = () => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()

  const submit = async (values) => await new Promise((resolve) => setTimeout(resolve, 5000))

  return (
    <Box w="100%" display="flex" pt={4}>
      <form onSubmit={handleSubmit(submit)}>
        <HStack justifySelf="flex-start">
          <FormControl isInvalid={errors.name}>
            <Input
              id="bid"
              placeholder="bid amount"
              {...register("bid", {
                required: "required",
                minLength: { value: 4, message: "Minimum length should be 4" },
              })}
            />
          </FormControl>
          <Button mt={4} p={4} size="md" bgColor="#7161EF" isLoading={isSubmitting} type="submit">
            place bid
          </Button>
        </HStack>
      </form>
    </Box>
  )
}
