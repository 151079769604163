import { BigNumber, ethers } from "ethers"
import { NFT } from "./types"

export const etherToHumanReadable = (b: string) =>
  ethers.utils.formatUnits(BigNumber.from(Math.trunc(Number(b))), 18)

export const getAbbreviatedAddress = (address?: string, options?: { startLength: number }) => {
  if (!address) return ""
  if (address.length < 10) return address
  const length = options?.startLength ?? 5
  return `${address.substr(0, length)}...${address.substr(address.length - 4)}`
}

export const getMinutesHours = (str) => {
  if (!str) return ""

  const date = new Date(str)
  const d = date.toLocaleDateString("en-GB")
  const m = date.getMinutes()
  const h = date.getHours()
  const s = date.getSeconds()

  return `${d} @ ${pad(h)}:${pad(m)}:${pad(s)}`
}

const pad = (s) => (s.toString().length === 1 ? s.toString().padStart(2, "0") : s)

export const transformOpenseaResponse = (data): NFT => {
  return {
    tokenId: data.token_id,
    description: data.description,
    name: data.name,
    imageOriginalUrl: data.image_original_url,
    tokenMetadata: data.token_metadata,
    key: generateNFTKeyFromResp(data.asset_contract.address, data.token_id),
    assetContract: {
      address: data.asset_contract.address,
      createdDate: data.created_date,
      collection: data.name,
    },
    bids: formatRecentBids(data.orders ?? []),
  }
}

const formatRecentBids = (d) =>
  d &&
  d.map((o) => ({
    date: o.created_date,
    address: o.maker.address,
    bid: o.current_price,
  }))

export const generateNFTKeyFromResp = (contract, id) => contract + ":" + id
