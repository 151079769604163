type NetworkNames = "MAINNET" | "RINKEBY"
type NetworkIDs = "1" | "4"

const Networks: Record<NetworkNames, NetworkIDs> = {
  MAINNET: "1",
  RINKEBY: "4",
}

export const OPENSEA_API = {
  [Networks.MAINNET]: "https://api.opensea.io/api/v1/",
  [Networks.RINKEBY]: "https://testnets-api.opensea.io/",
}

export const COLLECTION = {
  [Networks.MAINNET]: "0x5A533dc8ebdeCe75C43eb8459ea58c1A44eC4395",
  [Networks.RINKEBY]: "0x5A533dc8ebdeCe75C43eb8459ea58c1A44eC4395",
}

export const TIMEOUT = 8 * 1000
export const COLLECTION_URL = `https://testnets-api.opensea.io/api/v1/assets?asset_contract_addresses=${COLLECTION[1]}&order_direction=asc&offset=0&limit=16`
export const AUCTION_URL = `https://testnets-api.opensea.io/api/v1/asset/${COLLECTION[1]}/`
