import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useClipboard,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector"
import { UnsupportedChainIdError } from "@web3-react/core"

import { useMetaMask } from "../hooks/useMetaMask"
import { getAbbreviatedAddress } from "../lib"

export const ConnectWalletButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { status, connect, account } = useMetaMask()

  return (
    <>
      <Flex justify="flex-end" p={6}>
        <HStack align="center">
          <Button size="lg" bgColor="#CAEF61" borderRadius="32px" onClick={onOpen}>
            <Text color="black" fontSize="lg">
              {status === "connected" ? getAbbreviatedAddress(account || "") : "Connect Wallet"}
            </Text>
          </Button>
        </HStack>
      </Flex>
      <ConnectWalletModal
        isOpen={isOpen}
        onClose={onClose}
        status={status}
        connect={connect}
        account={account}
      />
    </>
  )
}

const metaMaskIcon = "https://cdn.iconscout.com/icon/free/png-256/metamask-2728406-2261817.png"

export const ConnectWalletModal = ({ onClose, isOpen, status, connect, account }) => {
  const { hasCopied, onCopy } = useClipboard(account)

  const isConnected = status === "connected"

  return (
    <>
      <Modal size="sm" closeOnOverlayClick={true} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent m="1em">
          <ModalHeader>{isConnected ? "connected" : "connect your wallet"}</ModalHeader>
          <ModalBody>
            <Text mb={2} color="gray">
              you must be whitelisted to place a bid
            </Text>

            <VStack align="stretch">
              {isConnected ? (
                <>
                  <Button
                    variant="outline"
                    size="lg"
                    onClick={onCopy}
                    justifyContent="space-between"
                    rightIcon={<Image width="2em" src={metaMaskIcon} />}
                  >
                    {getAbbreviatedAddress(account || "")}
                  </Button>
                  <Box>
                    <Button
                      variant="none"
                      color="gray"
                      size="xs"
                      onClick={onCopy}
                      rightIcon={<FontAwesomeIcon width="2em" icon={faCopy} />}
                    >
                      <Text>{hasCopied ? "copied" : "copy address"}</Text>
                    </Button>
                  </Box>
                </>
              ) : (
                <Button
                  variant="outline"
                  size="lg"
                  onClick={connect}
                  justifyContent="space-between"
                  rightIcon={<Image width="2em" src={metaMaskIcon} />}
                >
                  connect wallet
                </Button>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack alignItems="center"></HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const getErrorMessage = (error?: Error) => {
  console.log("ConnectWallet.tsx -- Error:", error)
  if (!error) return ""

  if (error instanceof NoEthereumProviderError) {
    return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile."
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network."
  } else if (error instanceof UserRejectedRequestErrorInjected) {
    return "Please authorize this website to access your Ethereum account."
  } else {
    // console.error(error)
    return "An unknown error occurred. Check the console for more details."
  }
}
