import { useLayoutEffect, useEffect, useRef, Dispatch, useCallback } from "react"

const useIsomorphicLayoutEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect

export function useSafeDispatch<T>(dispatch: Dispatch<T>) {
  const mountedRef = useRef(false)

  useIsomorphicLayoutEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  const safeDispatch = useCallback(
    (action: T) => {
      if (mountedRef.current) {
        dispatch(action)
      }
    },
    [dispatch],
  )

  return safeDispatch
}
