import { Box } from "@chakra-ui/react"

export const Backdrop = ({ enabled, children, ...rest }) => {
  return (
    <Box
      pt={10}
      height="100vh"
      minHeight="calc(100vh - 221px)" // meh
      bgColor={enabled ? "rgba(255, 255, 255, 0.15)" : "inherit"}
      {...rest}
    >
      {children}
    </Box>
  )
}
