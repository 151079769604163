import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react"
import { useParams } from "react-router-dom"

import { useFetchAuction } from "../../hooks/useSWR"
import { etherToHumanReadable } from "../../lib"
import { NFT } from "../../lib/types"
import { PlaceBid } from "./PlaceBid"
import { RecentBids } from "./RecentBids"

export const Auction = () => {
  const { id } = useParams<{ id: string }>()
  const { data } = useFetchAuction(id)

  if (!data) return <Skeleton />

  return (
    <Container maxW="container.xl">
      <Flex>
        <Box ml={10}>
          <AuctionCard nft={data} />
        </Box>

        <VStack w="50%" pl={4}>
          <Box w="auto">
            <Heading color="white" fontSize="4xl">
              {data.name}
            </Heading>
            <Text color="white" fontSize="xl">
              High above the clouds flies a working prototype of the first human designed
              antigravitic ship known as The Scarab. This exploration vessel is outfitted with a
              supply system to host up to 64 humans for long range missions throughout the
              Metaverse.
            </Text>
          </Box>
          <PlaceBid />
          <RecentBids nft={data} />
        </VStack>
      </Flex>
    </Container>
  )
}

const AuctionCard = ({ nft }: { nft: NFT }) => {
  const getLastBid = (bids) => bids.length && etherToHumanReadable(bids[0].bid)

  return (
    <Box maxW="lg" overflow="hidden" p="16px">
      <VStack>
        <Image src={nft.imageOriginalUrl} alt="" />

        <HStack w="full" justifyContent="space-between">
          <Text fontSize="lg" color="gray.500">
            last bid
          </Text>
          <HStack>
            <Text fontSize="lg" color="white">
              {getLastBid(nft.bids)}
            </Text>
            <Text fontSize="lg" color="gray.500">
              ETH
            </Text>
          </HStack>
        </HStack>

        <HStack w="full" justifyContent="space-between">
          <Text fontSize="lg" color="gray.500">
            edition
          </Text>
          <HStack>
            <Text fontSize="lg" color="white">
              {nft.tokenId}
            </Text>
            <Text fontSize="lg" color="gray.500">
              / 64
            </Text>
          </HStack>
        </HStack>

        <Divider />

        <HStack w="full" justifyContent="space-between">
          <Text fontSize="lg" color="white">
            type
          </Text>
          <Text fontSize="lg" color="gray.500">
            artifact
          </Text>
        </HStack>

        <HStack w="full" justifyContent="space-between">
          <Text fontSize="lg" color="white">
            district
          </Text>
          <Text fontSize="lg" color="gray.500">
            south
          </Text>
        </HStack>

        <HStack w="full" justifyContent="space-between">
          <Text fontSize="lg" color="white">
            address
          </Text>
          <Text fontSize="lg" color="gray.500">
            0600Y
          </Text>
        </HStack>
        <Box w="full">
          <Text fontSize="lg" color="white">
            powers:
          </Text>

          <Text fontSize="lg" color="gray.500">
            power of levitation <br />
            long range metaverse travel
          </Text>
        </Box>
      </VStack>
    </Box>
  )
}
