import React from "react"
import ReactDOM from "react-dom"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import { ColorModeScript } from "@chakra-ui/react"
import { resourceTheme } from "./theme"

ReactDOM.render(<App />, document.getElementById("root"))

// <React.StrictMode>
{
  /* <ColorModeScript initialColorMode={resourceTheme.config.initialColorMode} /> */
}
// </React.StrictMode>,

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
