import { Box, Heading, HStack, SimpleGrid, Skeleton, Text, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"

import { etherToHumanReadable, getAbbreviatedAddress, getMinutesHours } from "../../lib"
import { NFT } from "../../lib/types"

export const RecentBids = ({ nft }: { nft: NFT }) => {
  const [bids, setBids] = useState<any[]>([])

  useEffect(() => {
    if (nft && nft.bids) {
      setBids(nft.bids.slice(0, 5) ?? [])
    }
  }, [nft])

  if (!nft) return <Skeleton />

  return (
    <Box w="full" pt={6}>
      <VStack>
        <Box w="full">
          <Heading color="white" fontSize="4xl">
            recent bids
          </Heading>
        </Box>
        <SimpleGrid w="full">
          {bids &&
            bids.map((d) => (
              <>
                <HStack w="full" justifyContent="space-between">
                  <Text fontSize="xl" color="gray.500">
                    {getMinutesHours(d.date)}
                  </Text>

                  <Text fontSize="xl" color="gray.500">
                    {getAbbreviatedAddress(d.address)}
                  </Text>

                  <Text fontSize="xl" color="white">{`${etherToHumanReadable(d.bid)} eth`}</Text>
                </HStack>
              </>
            ))}
        </SimpleGrid>
      </VStack>
    </Box>
  )
}
