import { InjectedConnector } from "@web3-react/injected-connector"
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react"

import { useSafeDispatch } from "./useSafeDispatch"

export const injected = new InjectedConnector({ supportedChainIds: [1, 42, 1337] })

export interface MetaMaskState {
  account: null | string
  chainId: null | string
  status: "initializing" | "unavailable" | "disconnected" | "connected" | "connecting"
}

export interface IMetaMaskContext extends MetaMaskState {
  connect: () => Promise<string[] | null>
  ethereum: any
}

export const MetaMaskContext = createContext<IMetaMaskContext | undefined>(undefined)

type WindowInstanceWithEthereum = Window & typeof globalThis & { ethereum?: any }

async function synchronize(dispatch: (action: Action) => void) {
  const ethereum = (window as WindowInstanceWithEthereum).ethereum
  const isMetaMaskAvailable = Boolean(ethereum) && ethereum.isMetaMask
  if (!isMetaMaskAvailable) {
    dispatch({ type: "MetaMaskUnavailable" })
    return
  }

  const chainId: string = await ethereum.request({
    method: "eth_chainId",
  })

  const isUnlocked = await ethereum._metamask.isUnlocked()

  if (!isUnlocked) {
    dispatch({ type: "MetaMaskLocked", payload: { chainId } })
    return
  }

  const accessibleAccounts: string[] = await ethereum.request({
    method: "eth_accounts",
  })

  if (accessibleAccounts.length === 0) {
    dispatch({ type: "MetaMaskUnlocked", payload: { chainId } })
  } else {
    dispatch({
      type: "MetaMaskConnected",
      payload: { accounts: accessibleAccounts, chainId },
    })
  }
}

function subsribeToAccountsChanged(dispatch: (action: Action) => void) {
  const ethereum = (window as WindowInstanceWithEthereum).ethereum
  const onAccountsChanged = (accounts: string[]) =>
    dispatch({ type: "MetaMaskAccountsChanged", payload: accounts })
  ethereum.on("accountsChanged", onAccountsChanged)
  return () => {
    ethereum.removeListener("accountsChanged", onAccountsChanged)
  }
}

function subscribeToChainChanged(dispatch: (action: Action) => void) {
  const ethereum = (window as WindowInstanceWithEthereum).ethereum
  const onChainChanged = (chainId: string) =>
    dispatch({ type: "MetaMaskChainChanged", payload: chainId })
  ethereum.on("chainChanged", onChainChanged)
  return () => {
    ethereum.removeListener("chainChanged", onChainChanged)
  }
}

async function requestAccounts(dispatch: (action: Action) => void): Promise<string[]> {
  const ethereum = (window as WindowInstanceWithEthereum).ethereum

  dispatch({ type: "MetaMaskConnecting" })
  try {
    const accounts: string[] = await ethereum.request({
      method: "eth_requestAccounts",
    })
    dispatch({ type: "MetaMaskConnected", payload: { accounts } })
    return accounts
  } catch (e) {
    console.log("useMetaMask.tsx -- e:", e)
    dispatch({ type: "MetaMaskPermissionRejected" })
    throw e
  }
}

const initialState: MetaMaskState = {
  status: "initializing",
  account: null,
  chainId: null,
}

export function MetaMaskProvider(props: any) {
  const [state, unsafeDispatch] = useReducer(reducer, initialState)
  const dispatch = useSafeDispatch(unsafeDispatch)

  const { status } = state

  const isInitializing = status === "initializing"
  useEffect(() => {
    if (isInitializing) {
      synchronize(dispatch)
    }
  }, [dispatch, isInitializing])

  const isConnected = status === "connected"
  useEffect(() => {
    if (!isConnected) return () => {}
    const unsubscribe = subsribeToAccountsChanged(dispatch)
    return unsubscribe
  }, [dispatch, isConnected])

  const isAvailable = status !== "unavailable" && status !== "initializing"
  useEffect(() => {
    if (!isAvailable) return () => {}
    const unsubscribe = subscribeToChainChanged(dispatch)
    return unsubscribe
  }, [dispatch, isAvailable])

  const connect = useCallback(() => {
    if (!isAvailable) {
      console.warn(
        "`enable` method has been called while MetaMask is not available or synchronising. Nothing will be done in this case.",
      )
      return Promise.resolve([])
    }

    return requestAccounts(dispatch)
  }, [dispatch, isAvailable])

  const value: IMetaMaskContext = useMemo(
    () => ({
      ...state,
      connect,
      ethereum: isAvailable ? (window as WindowInstanceWithEthereum).ethereum : null,
    }),
    [connect, state, isAvailable],
  )

  return <MetaMaskContext.Provider value={value} {...props} />
}

interface MetaMaskUnavailable {
  type: "MetaMaskUnavailable"
}

interface MetaMaskLocked {
  type: "MetaMaskLocked"
  payload: {
    chainId: string
  }
}

interface MetaMaskUnlocked {
  type: "MetaMaskUnlocked"
  payload: {
    chainId: string
  }
}

interface MetaMaskConnected {
  type: "MetaMaskConnected"
  payload: {
    accounts: string[]
    chainId?: string
  }
}

interface MetaMaskDisconnected {
  type: "MetaMaskDisconnected"
}

interface MetaMaskConnecting {
  type: "MetaMaskConnecting"
}

interface PermissionRejected {
  type: "MetaMaskPermissionRejected"
}

interface AccountsChanged {
  type: "MetaMaskAccountsChanged"
  payload: string[]
}

interface ChainChanged {
  type: "MetaMaskChainChanged"
  payload: string
}

export type Action =
  | MetaMaskUnavailable
  | MetaMaskLocked
  | MetaMaskUnlocked
  | MetaMaskConnected
  | MetaMaskDisconnected
  | MetaMaskConnecting
  | PermissionRejected
  | AccountsChanged
  | ChainChanged

export function reducer(state: MetaMaskState, action: Action): MetaMaskState {
  switch (action.type) {
    case "MetaMaskUnavailable":
      return {
        chainId: null,
        account: null,
        status: "unavailable",
      }

    case "MetaMaskLocked":
      return {
        ...state,
        chainId: action.payload.chainId,
        account: null,
        status: "disconnected",
      }

    case "MetaMaskUnlocked":
      return {
        ...state,
        chainId: action.payload.chainId,
        account: null,
        status: "disconnected",
      }

    case "MetaMaskConnected":
      const unlockedAccounts = action.payload.accounts
      return {
        chainId: action.payload.chainId || state.chainId,
        account: unlockedAccounts[0],
        status: "connected",
      }

    case "MetaMaskConnecting":
      return {
        ...state,
        account: null,
        status: "connecting",
      }

    case "MetaMaskDisconnected":
      return {
        ...state,
        account: null,
        status: "disconnected",
      }

    case "MetaMaskPermissionRejected":
      return {
        ...state,
        account: null,
        status: "disconnected",
      }

    case "MetaMaskAccountsChanged":
      const accounts = action.payload
      if (accounts.length === 0) {
        return {
          ...state,
          account: null,
          status: "disconnected",
        }
      }
      return {
        ...state,
        account: accounts[0],
      }

    case "MetaMaskChainChanged":
      return {
        ...state,
        chainId: action.payload,
      }
  }
}

export function useMetaMask() {
  const context = useContext(MetaMaskContext)

  if (!context) {
    throw new Error("`useMetamask` should be used within a `MetaMaskProvider`")
  }

  return context
}
